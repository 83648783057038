import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
  inject
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
import { Route } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, switchMap, take, withLatestFrom } from 'rxjs/operators';

import { Address, NameValue, PropertyMatchBean } from '@ui/shared/models';
import { ModalService } from '@ui/legacy-lib';
import { MovingAuctionAddressForm, MovingAuctionForm } from '@ui/shared/models';

import * as fromAppState from 'tenant-pool/+state';
import * as fromActions from 'tenant-pool/+state/moving-auction/moving-auction.actions';
import * as fromSelectors from 'tenant-pool/+state/moving-auction/moving-auction.selectors';
import { MovingAuctionStepRoutes } from 'tenant-pool/components/moving-auction/moving-auction-step.routes';
import { MovingAuctionModalComponent } from 'tenant-pool/screens/moving-auction/moving-auction-modal/moving-auction-modal.component';
import { AsyncPipe } from '@angular/common';
import { ComponentsModule } from '@ui/legacy-lib';
import { Go } from '@ui/legacy-lib';
import { MovingAuctionContactComponent } from './moving-auction-contact/moving-auction-contact.component';
import { MovingAuctionDataComponent } from './moving-auction-data/moving-auction-data.component';
import { MovingAuctionAddressComponent } from './moving-auction-address/moving-auction-address.component';

@UntilDestroy()
@Component({
  selector: 'app-moving-auction-wizard',
  templateUrl: './moving-auction-wizard.component.html',
  styleUrls: ['./moving-auction-wizard.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    ComponentsModule,
    MovingAuctionAddressComponent,
    MovingAuctionDataComponent,
    MovingAuctionContactComponent,
    AsyncPipe
  ]
})
export class MovingAuctionWizardComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  private store = inject<Store<fromAppState.AppState>>(Store);
  private fb = inject(FormBuilder);
  private modalService = inject(ModalService);

  @Input() formData: MovingAuctionForm;
  @Input() rentedObjects: PropertyMatchBean[];

  public allSteps: {
    name: string;
    nameNice: string;
  }[];
  public currentStep$: Observable<number>;
  public processing$: Observable<boolean>;
  public currentForm$: Observable<FormGroup>;

  public rentedObjectsKeyValue: NameValue<Address>[];
  public selectedRentedObject: PropertyMatchBean;

  public form: FormGroup;

  public get getAddressFormValue() {
    return this.form.get('address')?.value as MovingAuctionAddressForm;
  }

  public setSelectedResidentObjectByAddress(address: Address): void {
    this.selectedRentedObject = this.rentedObjects.find(
      rentedObject => rentedObject.property.data.address === address
    );
  }

  public ngOnInit(): void {
    this.processing$ = this.store
      .select(fromSelectors.getSendRequestActionState)
      .pipe(map(actionState => actionState.pending));
    this.currentStep$ = this.store.select(
      fromAppState.getCurrentMovingAuctionWizardStep
    );
    this.allSteps = this.getAllSteps();
    this.form = this.fb.group({
      address: [null, Validators.required],
      data: [],
      contact: []
    });

    this.currentForm$ = this.currentStep$.pipe(
      map(
        (currentStep: number) =>
          this.form.get(this.allSteps[currentStep - 1].name) as FormGroup
      )
    );

    if (this.rentedObjects?.length > 0) {
      this.selectedRentedObject = this.rentedObjects[0];
    }

    if (this.rentedObjects?.length > 1) {
      this.rentedObjectsKeyValue = this.rentedObjects.map(object => ({
        name: object.property.data.name,
        value: object.property.data.address
      }));
    }

    this.store
      .select(fromSelectors.getSendRequestActionState)
      .pipe(
        filter(state => state.done || !!state.error),
        untilDestroyed(this),
        switchMap(() =>
          this.store.select(fromSelectors.getMovingAuctionResponse).pipe(
            filter(movingAuctionResponse => !!movingAuctionResponse),
            take(1)
          )
        ),
        withLatestFrom(this.store.select(fromSelectors.getRedirect))
      )
      .subscribe(([movingAuctionResponse, redirect]) => {
        if (movingAuctionResponse.responseType) {
          const path = redirect || ['objects', 'new-home'];
          this.store.dispatch(new Go({ path }));
          this.modalService.open<MovingAuctionModalComponent>(
            MovingAuctionModalComponent,
            {
              data: {
                movingAuctionResponse: {
                  responseType: movingAuctionResponse.responseType
                }
              },
              backdrop: 'static'
            }
          );
        }
      });
  }

  public ngAfterViewInit(): void {
    if (this.formData) {
      this.form.patchValue(this.formData);
    }
  }

  public ngOnDestroy(): void {
    this.store.dispatch(fromActions.MovingAuctionWizardReset());
  }

  public nextStep() {
    this.store.dispatch(fromActions.MovingAuctionWizardNextStep());
  }

  public completeStep() {
    const { address, ...rest } = this.form.value;
    const { selectAddress, ...restAddress } = address;
    const payload = {
      ...rest,
      address: {
        ...restAddress
      }
    };
    this.store.dispatch(
      fromActions.MovingAuctionSendRequest({
        movingAuctionForm: payload
      })
    );
  }

  public previousStep() {
    this.store.dispatch(fromActions.MovingAuctionWizardPreviousStep());
  }

  private getAllSteps() {
    return MovingAuctionStepRoutes.map((routeObject: Route) => ({
      name: routeObject.path,
      nameNice: routeObject.data.title
    }));
  }
}
